import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
// import Features from "../components/Features";
import BlogRoll from "../components/BlogRoll";
import FullWidthImage from "../components/FullWidthImage";
import SEO from "../components/seo"
import ContentLayout from "../components/ContentLayout";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  subheading,
  topContentText,
  topContentTagline,
  topContentImage,
  middleContentText,
  middleContentTagline,
  middleContentImage,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div style={{backgroundColor: "#E2DFDF"}}>
      <FullWidthImage img={heroImage} title={title} subheading={subheading} />
      <div>   
        <ContentLayout Image={topContentImage} Tagline={topContentTagline} Text={topContentText} imagePosition="right" />   
      </div>
      <center>
        <div>
          <div style={{padding: 30}}>
            <Link to='contact' >
              <h2 className="has-text-weight-semibold is-size-2" style={{color: "black"}}>
                Sign Up to Our Mailing List for updates
              </h2>
            </Link>

          </div>
          <div>
            <Link className="button" to='contact'>
              Sign Up
            </Link>
          </div>
        </div>
      </center>
      <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">

                  <div style={{paddingTop: 50}} className="column is-12">
                    <h3 className="has-text-weight-semibold ">
                      Blog
                    </h3>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered" >
                      <Link className="btn" to="/blog" >
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  topContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  topContentText:  PropTypes.string,
  topContentTagline:  PropTypes.string,
  middleContentImage:  PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  middleContentText:  PropTypes.string,
  middleContentTagline:  PropTypes.string,
  subheading: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <SEO title={frontmatter.meta.title} description={frontmatter.meta.description} />
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        topContentImage={frontmatter.topContentImage}
        topContentText={frontmatter.topContentText}
        topContentTagline={frontmatter.topContentTagline}
        middleContentImage={frontmatter.middleContentImage}
        middleContentText={frontmatter.middleContentText}
        middleContentTagline={frontmatter.middleContentTagline}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        topContentText
        topContentTagline
        topContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        middleContentText
        middleContentTagline
        middleContentImage {
          alt
          image {
            childImageSharp {
              gatsbyImageData(width: 526, quality: 92, layout: CONSTRAINED)
            }
          }
        }
        meta {
          title
          description
        }
      }
    }
  }
`;
